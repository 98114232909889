import React from "react";
import Service from "../../StaticData/Service.json";
import hospitalImg from "../../assets/Images/Homepage/Bestservice/Allfourservice/hos-admison.jpg"
import "./bestFeatures.scss";
import { faArrowRight } from "@fortawesome/fontawesome-free-solid";

import Card from "../../UI/UIComponent/Card";
const imgPath = require.context('../../assets/Images/Homepage/Bestservice/Allfourservice',true);
export default function BestService() {
  
  const handleClickReadMore = () => {
    // let modalRef = document.getElementById('commonModal');
    // modalRef.classList.add('show');
  };
  return (
    <div className="best-Service main-service container-fluid">
      <div className="card-section container">
        {Service.map((data, index) => (
          <Card
            imagesrc={imgPath(`./${data.Service_Image}.jpg`)}
            imgAltText="hospital-img"
            heading={data.Service_Name}
            description={data.Service_Desc}
            CTAText="Read More"
            ButtonClass="buttonText"
            fontIcon={faArrowRight}
            handleCallToaction={handleClickReadMore}
          ></Card>
        ))}
      </div>
    </div>
  );
}
