import {React,useEffect, useState} from "react";
import Card from "../../UI/UIComponent/Card";
import Button from "../../UI/UIComponent/Button";
import "./bestFeatures.scss";
import cardImg from "../../assets/Images/HospitalImages/samaritan-nursing-home-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/fontawesome-free-solid";
import { faArrowRight } from "@fortawesome/fontawesome-free-solid";
import BestDoctorList from "../../StaticData/BestDoctor.json";
import maleDoctorLogo from "../../assets/Images/Svg/Male-doctor-logo.svg";
import femaleDoctorLogo from "../../assets/Images/Svg/female-doctor-logo.svg";
import { getBestDoctors } from "../../api/services/homepage-service";

export default function BestDoctor() {
  const [doctorList, setDoctorList] = useState([]);
  useEffect(() => {
    getBestDoctors().then((data)=>setDoctorList(data));
  }, []);
  const handleReadMore = () => {
    let modalRef = document.getElementById("commonModal");
    modalRef.classList.add("show");
  };
  return (
    <div className="container-fluid">
      <div className="bestDoctor container">
        <div className="global-heading row">
          <div className="col-lg-8 p-0 col-md-12">
            <h2>Top Associate Doctors</h2>
            <span>
              Rever Health Solution proudly collaborates with top doctors,
              ensuring premium medical care. Experience excellence in healthcare
              with our trusted team. Your well-being is our priority.
            </span>
          </div>
          <div className="col-lg-4 p-0 text-position col-md-12">
            <Button primaryButton={true}>View All</Button>
          </div>
        </div>
        <div className="card-section container">
          {doctorList.map((data, index) => (
            <Card
              imagesrc={
                data.doctorPhoto === "" ? maleDoctorLogo : data.doctorPhoto
              }
              imgAltText="doctor-img"
              heading={data.name}
              description={data.doctorDept}
              CTAText="Consult Now"
              ButtonClass="buttonText"
              handleCallToaction={handleReadMore}
            ></Card>
          ))}
        </div>
      </div>
    </div>
  );
}
