import { React, useEffect, useState, useRef, useReducer } from 'react'
import { faPhone } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './FloatingWidget.scss'
import Input from '../UIComponent/Input'
import Button from '../UIComponent/Button'
import BannerImage from '../../assets/Images/Homepage/healthcard_banner.png'
import { getOTP } from '../../api/services/global-service'

const FullNameReducer = (state, action) => {
  if (action.type === 'user_Name') {
    return { value: action.value, isFullNameValid: action.value.length > 3 }
  }
}
const EmailReducer = (state, action) => {
  if (action.type === 'user_email') {
    return { value: action.value, isEmailValid: action.value.includes('@') }
  }
}
const PhoneReducer = (state, action) => {
  if (action.type === 'user_ph') {
    return { value: action.value, isPhValid: action.value.length === 10 }
  }
}
export default function FloatingWidget(props) {
  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState(true)
  const [formValid, setFormValid] = useState(false)
  const [encodeData, setEncodedData] = useState('')
  const [setFullName, DispatcherNameEvent] = useReducer(FullNameReducer, {
    value: '',
    isFullNameValid: null,
  })
  const [setEmail, DispatcherEmailEvent] = useReducer(EmailReducer, {
    value: '',
    isEmailValid: null,
  })
  const [setPhoneNo, DispatcherPhoneEvent] = useReducer(PhoneReducer, {
    value: '',
    isPhValid: null,
  })

  const onNameChange = (e) => {
    DispatcherNameEvent({
      value: e.target.value,
      type: 'user_Name',
    })
  }
  const onEmailChange = (e) => {
    DispatcherEmailEvent({
      value: e.target.value,
      type: 'user_email',
    })
  }
  const onPhoneChange = (e) => {
    DispatcherPhoneEvent({
      value: e.target.value,
      type: 'user_ph',
    })
  }

  const { isFullNameValid: Namevalid } = setFullName
  const { isEmailValid: EmailValid } = setEmail
  const { isPhValid: PhoneNoValid } = setPhoneNo

  useEffect(() => {
    // const handleScroll = () => {
    //   if (window.screen.width > 1024) {
    //     const currentScrollY = window.scrollY
    //     if (prevScrollY.current < currentScrollY && goingUp) {
    //       setGoingUp(false)
    //       document.getElementById('floating-widget').classList.add('mob-float')
    //     }
    //     if (prevScrollY.current > currentScrollY && !goingUp) {
    //       setGoingUp(true)
    //       // if (document.getElementById('floating-widget')) {
    //       //   document.getElementById('floating-widget').classList.remove('mob-float');
    //       // }
    //     }

    //     prevScrollY.current = currentScrollY
    //   }
    // }
    setFormValid(Namevalid && EmailValid && PhoneNoValid)
    //window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      //window.removeEventListener('scroll', handleScroll)
    }
  }, [goingUp, Namevalid, EmailValid, PhoneNoValid])

  const onSubmitData = async () => {
    let encodedData
    const payload = {
      patient_name: setFullName.value,
      patient_email: setEmail.value,
      patient_contactNo: setPhoneNo.value,
      query_type: 'Health Card',
      queryRaisedOn: new Date(),
    }
    if (formValid) {
      const returnPayload = await handleRequestOtp({
        phone_Number: payload.patient_contactNo,
        type: 'phone',
      })
      let modalRef = document.getElementById('otpModal')
      modalRef.classList.add('show')
      setEncodedData(returnPayload.data)
      encodedData = returnPayload.data
      props.parentCallback({ ...payload, encodedData })
    } else {
      if (setFullName.value === '' && setFullName.value.length <= 3) {
        DispatcherNameEvent({
          value: '',
          type: 'user_Name',
        })
      }
      if (setEmail.value === '' && !setEmail.value.includes('@')) {
        DispatcherEmailEvent({
          value: '',
          type: 'user_email',
        })
      }
      if (setPhoneNo.value === '' && !setPhoneNo.value.length < 10) {
        DispatcherPhoneEvent({
          value: '',
          type: 'user_ph',
        })
      }
    }
  }
  const handleRequestOtp = async (payload) => {
    try {
      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(payload),
      // };
      // const response = await fetch(
      //   "http://localhost:4000/app/getOTP",
      //   requestOptions
      // );
      const data = await getOTP(payload)
      const responseData = data
      return responseData
    } catch (error) {
      throw error
    }
  }

  const handlewidgetClick = () => {
    // document.getElementById('floating-widget').classList.length === 1
    //   ? document.getElementById('floating-widget').classList.add('mob-float')
    //   : document.getElementById('floating-widget').classList.remove('mob-float')
    let modalRef = document.getElementById('floatingwidget-modal')
    modalRef.classList.add('show')
  }
  const handlePopupClose = () => {
    // window.screen.width > 1024
    //   ? document.getElementById('floating-widget').classList.add('mob-float')
    //   : document.getElementById('floating-widget').classList.remove('mob-float')
    let modalRef = document.getElementById('floatingwidget-modal')
    modalRef.classList.remove('show')
  }

  return (
    <>
      {/* <div id="floating-widget" className="FloatingWidget">
        <div>
          <div className="banner-image w-100 d-flex">
            <div className="float-band" onClick={handlewidgetClick}>
              <span className="float-band-text">Health Benifit Card</span>
            </div>
            <img
              src={BannerImage}
              alt="RHS Consultaion Rs.200"
              className="w-100"
            ></img>
            <button className="close-btn" onClick={handlePopupClose}>
              <FontAwesomeIcon icon="fas fa-window-close" />
            </button>
          </div>
          <div className="description">
            <ul>
              <li>
                {' '}
                1.With this card, the members are entitled to get benefit from
                Pathology, Radiology, Health Counselling, Psychological
                Counselling, Physiotherapy, Green Therapy. (Acupressure – Pain
                Managment), Doctor’s appointment. (As per need), Hospital
                Emergency Admission, Home care technical health support,
                Ambulance Service, Medicine Home delivery, Dietician &
                Nutritionist.
              </li>
              <li>2.15% discount on pharmacy.</li>
            </ul>
          </div>
          <div className="health-card-form container pt-3">
            <div className="row">
              <div className="col-12">
                <Input
                  inputPlaceholder="Enter Your Name*"
                  handleonChange={onNameChange}
                  isInputValid={Namevalid}
                ></Input>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3 mb-3">
                <Input
                  inputPlaceholder="Enter Your Email*"
                  handleonChange={onEmailChange}
                  isInputValid={EmailValid}
                ></Input>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  inputPlaceholder="Enter Your Phone*"
                  handleonChange={onPhoneChange}
                  isInputValid={PhoneNoValid}
                ></Input>
              </div>
            </div>
            <div className="row pt-3 pb-3 justify-content-center">
              <Button
                className="float-formBtn"
                primaryButton={true}
                buttonClickHandler={onSubmitData}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <div id="floating-widget" className="FloatingWidget">
        <div className="banner-image w-100 d-flex">
          <div className="float-band" onClick={handlewidgetClick}>
            <span className="float-band-text">Health Benifit Card</span>
          </div>
        </div>
        <div
        class="modal fade common-modal"
        id="floatingwidget-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="commonModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            {/* <div class="modal-header">
              <img src={BannerImage} alt="Rever Health"></img>
              
            </div> */}
            <div class="modal-body p-0">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="commonModal"
                aria-label="Close"
                onClick={handlePopupClose}
              >
                {/* <span aria-hidden="true"></span> */}
              </button>
            </div>
                <div>
                  <div className="banner-image w-100 d-flex">
                    <img
                      src={BannerImage}
                      alt="RHS Consultaion Rs.200"
                      className="w-100"
                    ></img>
                  </div>
                  <div className="description">
                    <ul>
                      <li>
                        {' '}
                        1.With this card, the members are entitled to get
                        benefit from Pathology, Radiology, Health Counselling,
                        Psychological Counselling, Physiotherapy, Green Therapy.
                        (Acupressure – Pain Managment), Doctor’s appointment.
                        (As per need), Hospital Emergency Admission, Home care
                        technical health support, Ambulance Service, Medicine
                        Home delivery, Dietician & Nutritionist.
                      </li>
                      <li>2.15% discount on pharmacy.</li>
                    </ul>
                  </div>
                  <div className="health-card-form container pt-3">
                    <div className="row">
                      <div className="col-12">
                        <Input
                          inputPlaceholder="Enter Your Name*"
                          handleonChange={onNameChange}
                          isInputValid={Namevalid}
                        ></Input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3 mb-3">
                        <Input
                          inputPlaceholder="Enter Your Email*"
                          handleonChange={onEmailChange}
                          isInputValid={EmailValid}
                        ></Input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Input
                          inputPlaceholder="Enter Your Phone*"
                          handleonChange={onPhoneChange}
                          isInputValid={PhoneNoValid}
                        ></Input>
                      </div>
                    </div>
                    <div className="row pt-3 pb-3 justify-content-center">
                      <Button
                        className="float-formBtn"
                        primaryButton={true}
                        buttonClickHandler={onSubmitData}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </>
  )
}
