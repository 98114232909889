import React from "react";
import Button from "../../UI/UIComponent/Button";
import topSpecialityService from "../../StaticData/SpecialityService.json";
import "./topSpeciality.scss";
import Image from "../../UI/UIComponent/Image";

export default function TopSpeciality() {
  const imgPath = require.context('../../assets/Images/Homepage/Bestservice/TopSpeciality',true);
  return (
    <div className="top-speciality container-fluid">
      <div className="container">
        <div className="global-heading row">
          <div className="col-lg-8 p-0 col-md-12">
            <h2>Top Specialities</h2>
            <span>
              Private consultations with verified doctors in all specialists
            </span>
          </div>
          <div className="col-lg-4 text-position p-0 col-md-12 d-none d-lg-block">
            <Button primaryButton={true}>View All</Button>
          </div>
        </div>
      </div>

      <div className="service-wrapper container p-0">
        {topSpecialityService.map((data, index) => (
          <div className="features">
            <div className="facility-image">
              <img src={imgPath(`./${data.Speciality_Img}.svg`)} alt={data.Speciality_ImgAltText}></img>
            </div>
            <div className="facility-desc">{data.Speciality_Name}</div>
            {/* <a href="/">{data.Button_Text}</a> */}
          </div>
        ))}
      </div>
      <div className="text-center mt-5 d-lg-none .d-none .d-sm-block .d-md-none">
      <Button primaryButton={true}>View All</Button>
      </div>
    </div>
  );
}
