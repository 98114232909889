import { React, useState } from 'react'
import Button from '../../UI/UIComponent/Button'
import StaticData from '../../StaticData/staticData.json'
import BlsAmbulance from '../../assets/Images/Homepage/BLS_ambulance.png'
import AlsAmbulance from '../../assets/Images/Homepage/ALS_ambulance.png'
import PtsAmbulance from '../../assets/Images/Homepage/PTS_ambulance.png'
import AasAmbulance from '../../assets/Images/Homepage/AAS_ambulance.png'
import O2Icon from '../../assets/Images/Svg/O2-icon.png'
import FirstAidIcon from '../../assets/Images/Svg/firstAid-icon.png'
import StratureIcon from '../../assets/Images/Svg/strature-icon.png'
import WheelChairIcon from '../../assets/Images/Svg/wheel-chair-icon.png'


export default function AmbulanceService() {
  const ambImgPath = require.context(
    '../../assets/Images/Homepage/Bestservice/Ambulance',
    true,
  )
  const [ambActive, setAmbActive] = useState('PTS');
  const handleAmbulanceChange = (e) => {
    const ambTypeSelect = e.target.dataset.typecode
    setAmbActive(ambTypeSelect)
  }
  
  return (
    <>
      <div className="bestService">
        <div className="container-fluid p-0">
          <div className="container p-0">
            <div className="global-heading row">
              <div className="col-lg-8 p-0 col-md-12">
                <h2>{StaticData.Ambulance.Heading}</h2>
                <span>{StaticData.Ambulance.Subheading}</span>
              </div>
              <div className="col-lg-4 p-0 text-position col-md-12">
                <Button primaryButton={true}>
                  {StaticData.Ambulance.Button_Text}
                </Button>
              </div>
            </div>
          </div>
          <div className="ambulance-type">
            {StaticData.Ambulance.Ambulance_Type.map((data, index) => (
              <div className={`ambulance-card ${data.Ambulance_Type_Code === ambActive ? 'active' : ''}`}  onClick={handleAmbulanceChange} data-typecode={data.Ambulance_Type_Code}>
                <div data-typecode={data.Ambulance_Type_Code}>
                  <img alt="RHS Ambulance Service" className='amb-icon' src={data.Ambulance_Type_Code === ambActive ? ambImgPath(`./${data.Ambulance_Image}${`-w`}.svg`) : ambImgPath(`./${data.Ambulance_Image}${`-r`}.svg`)}
                    data-typecode={data.Ambulance_Type_Code}
                  ></img>
                </div>
                <div
                  className="ambulance-code-text"
                  data-typecode={data.Ambulance_Type_Code}
                >
                  <span data-typecode={data.Ambulance_Type_Code}>
                    {data.Ambulance_Type_Code}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ambulance-details">
        <div className="container-fluid ambulanceSection">
          <div className="ambulance-image">
            {(() => {
              switch (ambActive) {
                case 'BLS':
                  return (
                    <img src={BlsAmbulance} alt="RHS Basic Life Support Ambulance" className='w-100'></img>
                  )
                case 'ALS':
                  return (
                    <img src={AlsAmbulance} alt="RHS Basic Life Support Ambulance" className='w-100'></img>
                  )
                  case 'AAS':
                  return (
                    <img src={AasAmbulance} alt="RHS Basic Life Support Ambulance" className='w-100'></img>
                  )
                  case 'PTS':
                  return (
                    <img src={PtsAmbulance} alt="RHS Basic Life Support Ambulance" className='w-100'></img>
                  )
                default:
                  return (<img src={BlsAmbulance} alt="RHS Basic Life Support Ambulance" className='w-100'></img>)
              }
            })()}
          </div>
          <div className="ambulance-description">
            <div className="heading">
              
              {(() => {
              switch (ambActive) {
                case 'BLS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[0].Heading}</span>
                  )
                case 'ALS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[1].Heading}</span>
                  )
                  case 'AAS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[3].Heading}</span>
                  )
                  case 'PTS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[2].Heading}</span>
                  )
                default:
                  return (<span>{StaticData.Ambulance.Ambulance_details[0].Heading}</span>)
              }
            })()}
            </div>
            <div className="subheading">
            {(() => {
              switch (ambActive) {
                case 'BLS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[0].Subheading}</span>
                  )
                case 'ALS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[1].Subheading}</span>
                  )
                  case 'AAS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[2].Subheading}</span>
                  )
                  case 'PTS':
                  return (
                    <span>{StaticData.Ambulance.Ambulance_details[3].Subheading}</span>
                  )
                default:
                  return (<span>{StaticData.Ambulance.Ambulance_details[0].Heading}</span>)
              }
            })()}
              {/* <span>
                {StaticData.Ambulance.Ambulance_details[0].Subheading}
              </span> */}
            </div>
            <div className="basic-icons">
            {(() => {
              switch (ambActive) {
                case 'BLS':
                  return (
                    <>
                      <img src={O2Icon} alt="o2 Icon"></img>
                      <img src={FirstAidIcon} alt="firstaid Icon"></img>
                      <img src={StratureIcon} alt="strature Icon"></img>
                      <img src={WheelChairIcon} alt=" Icon"></img>
                      <img src={ambImgPath(`./air_conditioner.svg`)} alt="Air conditioner" width={71}></img>
                    </>
                    
                  )
                case 'ALS':
                  return (
                    <>
                      <img src={O2Icon} alt="o2 Icon"></img>
                      <img src={FirstAidIcon} alt="firstaid Icon"></img>
                      <img src={StratureIcon} alt="strature Icon"></img>
                      <img src={WheelChairIcon} alt=" Icon"></img>
                      <img src={ambImgPath(`./portable-suction.svg`)} alt="portable suction" width={71}></img>
                      <img src={ambImgPath(`./defibrillator.svg`)} alt="Defibrillator" width={71}></img>
                      <img src={ambImgPath(`./paramount_monitor.svg`)} alt="paramount monitor" width={71}></img>
                      <img src={ambImgPath(`./syringe_pump.svg`)} alt="syringe pump" width={71}></img>
                      <img src={ambImgPath(`./air_conditioner.svg`)} alt="Air conditioner" width={71}></img>
                    </>
                  )
                  case 'AAS':
                  return (
                    <>
                      <img src={O2Icon} alt="o2 Icon"></img>
                      <img src={FirstAidIcon} alt="firstaid Icon"></img>
                      <img src={StratureIcon} alt="strature Icon"></img>
                      <img src={WheelChairIcon} alt=" Icon"></img>
                      <img src={ambImgPath(`./portable-suction.svg`)} alt="portable suction" width={71}></img>
                      <img src={ambImgPath(`./defibrillator.svg`)} alt="Defibrillator" width={71}></img>
                      <img src={ambImgPath(`./paramount_monitor.svg`)} alt="paramount monitor" width={71}></img>
                      <img src={ambImgPath(`./syringe_pump.svg`)} alt="syringe pump" width={71}></img>
                      <img src={ambImgPath(`./air_conditioner.svg`)} alt="Air conditioner" width={71}></img>
                    </>
                  )
                  case 'PTS':
                  return (
                    <>
                      <img src={O2Icon} alt="o2 Icon"></img>
                      <img src={FirstAidIcon} alt="firstaid Icon"></img>
                      <img src={StratureIcon} alt="strature Icon"></img>
                      <img src={WheelChairIcon} alt=" Icon"></img>
                    </>
                  )
                default:
                  return (<>
                      <img src={O2Icon} alt="o2 Icon"></img>
                      <img src={FirstAidIcon} alt="firstaid Icon"></img>
                      <img src={StratureIcon} alt="strature Icon"></img>
                      <img src={WheelChairIcon} alt=" Icon"></img>
                    </>)
              }
            })()}
            </div>
            <div className="button-section">
              <Button primaryButton={false}>Book Now!</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
