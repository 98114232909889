import { React, useEffect } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import dummyImage from '../../src/assets/Images/Global/small-banner.png'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './Page-style/About-page.scss'
import Contents from '../../src/StaticData/aboutus-page-content.json'
import StaticBanner from '../FeatureComponent/StaticBanner/staticBanner'
import HomepageBanner from '../UI/Banner/HomepageBanner'
import aboutImage from '../assets/Images/AboutPage/about.png'
import missionVissionFirst from '../assets/Images/AboutPage/missionvision1.png'
import missionVissionSecond from '../assets/Images/AboutPage/missionvision2.png'
import directorImage from '../assets/Images/AboutPage/Director_Image.png'
import aboutBanner from '../assets/Images/AboutPage/banner-desktop.png'

export default function HomepageModule() {
  const ImgPath = require.context('../assets/Images/AboutPage/', true)
  useEffect(() => {
    const timerFunc = setTimeout(() => {
      let selectedNavItem = document.getElementsByClassName('nav-link active')
      selectedNavItem[0].classList.remove('active')
      document.getElementById('id-about').classList.add('active')
    })
    return () => {
      clearTimeout(timerFunc)
    }
  }, [])

  return (
    <>
      <Header></Header>
      <section>
        {/* <HomepageBanner></HomepageBanner> */}
        <div className='homepage-banner'>
        <img src={aboutBanner} alt='RHS About Page Banner' className='w-100'></img>
        </div>
        <div className="about-page">
          <div className="about-page_intro container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="about-page_heading">
                    <span>{Contents.Maincontent.heading}</span>
                  </div>
                  <div className="about-page_subheading">
                    <span>{Contents.Maincontent.subheading}</span>
                  </div>
                  <div className="about-page_introduction">
                    <p>{Contents.Maincontent.introduction}</p>
                  </div>
                  <div className="about-page_label">
                    <span>{Contents.Maincontent.paralabel}</span>
                  </div>
                  <div className="about-page_services">
                    <ul>
                      {Contents.Maincontent.labelitems.map((data, index) => (
                        <li>{data}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="about-page_intro-para">
                    <p>{Contents.Maincontent.para}</p>
                  </div>
                </div>
                <div className="col-lg-6 about-image-group">
                  <img src={aboutImage} alt="about RHS" className="w-100"></img>
                </div>
              </div>
            </div>
          </div>
          <StaticBanner></StaticBanner>
          <div className="">
            <div className="about-page_director-desk">
              <div className="container-fluid">
                <div className="container">
                  <div className="row custom-row">
                    <div className="col-lg-6">
                      <div className="about-page_heading">
                        <span>{Contents.directorDesk.heading}</span>
                      </div>
                      <div className="about-page_director-intro">
                        <p className="">
                          <span className="about-page_director-name">
                            {Contents.directorDesk.directorName}
                          </span>
                          {Contents.directorDesk.introduction}
                        </p>
                      </div>
                      <div className="about-page_label">
                        <span>{Contents.directorDesk.paralabel}</span>
                      </div>
                      <div className="about-page_services">
                        <ul>
                          {Contents.directorDesk.labelitems.map(
                            (data, index) => (
                              <>
                                <li>{data.itemName}</li>
                                <li>{data.itemDesc}</li>
                              </>
                            ),
                          )}
                        </ul>
                      </div>
                      <div className="about-page_intro-para">
                        <p>{Contents.directorDesk.para}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 about-page_director-desk_director-image">
                      <img src={directorImage} alt="RHS Director" className='about-page_director-desk_director-image_image'></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-page_mission-vision container-fluid">
              <div className="container">
                <div className="row custom-row">
                  {Contents.ourMissionVision.content.map((data, index) => (
                    <>
                      <div className="col-lg-4">
                        <img
                          src={ImgPath(`./${data.itemImage}.png`)}
                          className="w-100"
                          alt={data.itemAlttext}
                        ></img>
                      </div>
                      <div className="col-lg-8">
                        <div className="about-page_heading">
                          <span>{data.itemHeading}</span>
                        </div>
                        <div className="about-page_introduction">
                          <p>{data.itemDesc}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </section>
    </>
  )
}
